<template lang="pug">
div.container
  div(v-if="!events.length > 0")
    loading-spinner(message="Loading events...")

  div(v-if="events.length > 0")
    b-row(align-h="between" align-v="center")
      h1 Manage events <router-link to="/events/new"><b-button variant="success"><fa-icon icon="plus" size="lg"></fa-icon></b-button></router-link>
      b-form(inline)
        b-input(type="text" placeholder="search" v-model="searchText")

    div.row
      table.table.table-bordered.table-hover.table-striped
        thead.thead-dark
          tr
            th Organizer
            th Event Name
            th Sponsor
            th Venue
            th Visitors
            th Date
        tbody
          tr.table-row-link(v-for="event in searchResults" :key="event._id" @click="$router.push('/events/edit/' + event._id)")
            td {{event.organizer}}
            td {{event.name}}
            td {{event.sponsor}}
            td {{event.location}}
            td {{parseInt(event.totalVisitors) > 0 ? event.totalVisitors : "-"}}
            td {{event.date | moment("MMMM YYYY")}}

    div.row.justify-content-center
        p {{searchResults.length}} results
</template>

<script>
export default {
  data: function () {
    return {
      searchText: '',
      events: []
    }
  },
  computed: {
    searchResults: function () {
      if (this.searchText === '' || this.searchText == null) return this.events
      else {
        return this.events.filter(event => {
          let s = this.searchText.toLowerCase()
          let hit = false
          if (event.hasOwnProperty('organizer')) {
            if (event.organizer.toLowerCase().includes(s)) { hit = true }
          } else if (event.hasOwnProperty('name')) {
            if (event.name.toLowerCase().includes(s)) { hit = true }
          } else if (event.hasOwnProperty('sponsor')) {
            if (event.sponsor.toLowerCase().includes(s)) { hit = true }
          } else if (event.hasOwnProperty('location')) {
            if (event.location.toLowerCase().includes(s)) { hit = true }
          }

          return hit
        })
      }
    }
  },
  mounted: function () {
    this.axios.get('/api/events')
      .then(res => {
        // Compute visitor counts
        for (let event of res.data) {
          event.date = new Date(event.date)
          if (event.visitorCountOverride > 0) { event.totalVisitors = event.visitorCountOverride } else { event.totalVisitors = event.visitors.length + event.visitorCountOffset }
        }
        res.data.sort(function (a, b) { return b.date - a.date })

        this.events = res.data
      })
      .catch(err => {
        this.$notify({
          group: 'error',
          type: 'error',
          title: 'Fetching events failed',
          text: err.response.data,
          duration: -1
        })
      })
  }
}
</script>
